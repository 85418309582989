import request from "@/axios/index";
// import qs from 'qs'
// 图表设置保存
export function setcharts(data) {
  return request({
    url: '/chart_config',
    method: "post",
    data
  })
}
export function setchartsv2(data) {
  return request({
    url: '/chart_config/v2',
    method: "post",
    data
  })
}