<template>
    <div class="setchanrt">
        <div class="topBox">
            <!-- <el-button type="danger" @click="cancelClick">取消</el-button> -->
            <el-button type="text" icon="el-icon-back" @click="cancelClick"></el-button>
            <el-button type="success" @click="handleSave">保存</el-button>
            <!-- <el-button type="info">预览</el-button> -->
            <el-button type="primary" @click="selectChart">选择图表</el-button>
            <el-input v-model="chanrtData.catName" size="small" style="width: 300px; margin-left: 10px;" placeholder="标题"></el-input>
            <el-input v-model="canvasHeight" size="small" style="width: 300px; margin-left: 10px;" placeholder="画布高度/单位px" @input="handleBlur"></el-input>
            <!-- <el-input v-model="canvasWidth" size="small" style="width: 300px; margin-left: 10px;" placeholder="画布宽度/单位px" @blur="handlewidthBlur"></el-input> -->
        </div>
        <div class="chartsBox">
            <div ref="canvasBox" class="leftbox drag-resize-area" style="position:relative">
                <!-- <div v-for="(item, index) in chanrtData.chartChturs" :key="index" :id="item.id" style="height:400px;width:400px" class="chartsBoxSun" @dblclick="dbclickcharts(item, index)"></div> -->
                <Y-drag
                    :isResize ='true'
                    v-for="(item, index) in chanrtData.chartChturs"
                    :key="index"
                    class="drag-item-echarts"
                    :dblclickObj="item"
                    :coordinateX="item.coordinateX"
                    :coordinateY="item.coordinateY"
                    :widthCount="item.width"
                    :heightCount="item.height"
                    @dblclick.native="dbclickcharts(item, index)"
                >
                    <div class="drag-item-echarts-histogram" :id="item.chartsId"></div>
                </Y-drag>
            </div>
        </div>
        <el-drawer
            title="选择图表类型"
            :visible.sync="drawer"
            direction="ltr"
            :before-close="handleClose"
            size="40%"
        >
            <div v-for="item in imgboxdata" :key="item.id" @click="chartClick(item)" class="imgbox">
                <img :src="item.url">
                <span>{{ item.name }}</span>
            </div>
        </el-drawer>
        <el-drawer
            title="数据修改"
            :visible.sync="dataDrawer"
            :modal="false"
            direction="rtl"
            :before-close="dataDrawerhandleClose"
            size="20%"
        >
            <Y-form v-if="formdisabled" :dataSourceCondition="dataSourceCondition" :chartsTypeed="chartsType" :dataSourceOption="dataSourceOption" @dataSelectChange="dataSelectChange" @deletelist="deletelist"/>
            <el-form :model="positionData" label-width="80px">
                <el-form-item label="宽度">
                    <el-input size="mini" style="width:214px" v-model="positionData.width"></el-input>
                </el-form-item>
                <el-form-item label="高度">
                    <el-input size="mini" style="width:214px" v-model="positionData.height"></el-input>
                </el-form-item>
                <el-form-item label="距左">
                    <el-input size="mini" style="width:214px" v-model="positionData.coordinateX"></el-input>
                </el-form-item>
                <el-form-item label="距上">
                    <el-input size="mini" style="width:214px" v-model="positionData.coordinateY"></el-input>
                </el-form-item>
                <el-form-item label="max字段">
                    <el-select v-model="maxValue" @change="changemax" size="small" multiple placeholder="请选择">
                        <el-option
                            v-for="(item, index) in maxValueOptions"
                            :key="index"
                            :label="item.label"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </el-drawer>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import { getdataSetlist } from "@/api/dataSet/index.js"
import {EleResize} from '@/utils/esresize'
import clone from "clone"
import { setcharts, setchartsv2 } from '@/api/setchartlist/index.js'
import { getChartsList, getChartsData } from "@/api/moveCharts/moveCharts.js"
import { getdataBaseLinkDetailt } from "@/api/dataSet/index.js"
export default {
    name: '',
    data () {
        return {
            chanrtData: {
                catName: null,
                id: null,
                chartChturs: [],
                canvasHeight: 0,
            },
            canvasHeight: '', // 画布大小
            canvasWidth: '',
            dataSourceOption: [],
            maxValue: "",
            maxValueOptions: [],
            titleInput: '',
            drawer: false,
            optionsid: 0,
            formdisabled: false,
            dataSourceCondition: {},
            positionData: {},
            chartsIndex: 0,
            dataDrawer: false,
            xAxisAxisLabel:{
                interval: 0,
                rotate: 30,
                formatter: function(value) {
                    if (value.length > 6) {
                        return value.substring(0, 7) + "...";
                    } else {
                        return value;
                    }
                }
            },
            yAxisAxisLabel: {
                color: "#000",
                interval: 0,
                formatter: function(value) {
                    if (value >= 100 && value < 1000) {
                        return (value / 100) + "百"
                    } else if (value >= 1000 && value < 10000) {
                        return (value / 1000) + "千"
                    } else if (value >= 10000 && value < 100000) {
                        return (value / 10000) + "万"
                    } else if (value >= 100000 && value < 1000000) {
                        return (value / 100000) + "十万"
                    } else if (value >= 1000000 && value < 10000000) {
                        return (value / 1000000) + "百万"
                    } else if (value >= 10000000 && value < 100000000) {
                        return (value / 10000000) + "千万"
                    } else if (value >= 100000000 && value < 1000000000) {
                        return (value / 100000000) + "亿"
                    } else if (value >= 1000000000 && value < 10000000000) {
                        return (value / 1000000000) + "十亿"
                    } else if (value >= 10000000000 && value < 100000000000) {
                        return (value / 10000000000) + "百亿"
                    } else if (value >= 100000000000 && value < 1000000000000) {
                        return (value / 100000000000) + "千亿"
                    } else if (value >= 1000000000000 && value < 10000000000000) {
                        return (value / 1000000000000) + "兆"
                    } else if (value >= 10000000000000 && value < 100000000000000) {
                        return (value / 10000000000000) + "十兆"
                    } else if (value >= 100000000000000 && value < 1000000000000000) {
                        return (value / 100000000000000) + "百兆"
                    } else if (value >= 1000000000000000 && value < 10000000000000000) {
                        return (value / 1000000000000000) + "千兆"
                    } else if (value >= 10000000000000000 && value < 100000000000000000) {
                        return (value / 10000000000000000) + "京"
                    } else if (value >= 100000000000000000 && value < 1000000000000000000) {
                        return (value / 100000000000000000) + "十京"
                    } else if (value >= 1000000000000000000 && value < 10000000000000000000) {
                        return (value / 1000000000000000000) + "百京"
                    } else if (value >= 10000000000000000000 && value < 100000000000000000000) {
                        return (value / 10000000000000000000) + "千京"
                    } else if (value >= 100000000000000000000 && value < 1000000000000000000000) {
                        return (value / 100000000000000000000) + "垓"
                    } else if (value >= 1000000000000000000000 && value < 10000000000000000000000) {
                        return (value / 1000000000000000000000) + "十垓"
                    } else if (value >= 10000000000000000000000 && value < 100000000000000000000000) {
                        return (value / 10000000000000000000000) + "百垓"
                    } else if (value >= 100000000000000000000000 && value < 1000000000000000000000000) {
                        return (value / 100000000000000000000000) + "千垓"
                    } else {
                        return value
                    }
                }
            },
            optionInformation: {
                id: null,
                chartsId: '',
                chartsType: '',
                coordinateX: 0,
                coordinateY: 0,
                width: 400,
                height: 300,
                title: '',
                options: {},
                chartSet: {},
            },
            chartsType: '',
            imgboxdata: [
                {
                    url: require('@/assets/img1.jpg'),
                    name: '普通柱状图',
                    type: 'histogram',
                },
                {
                    url: require('@/assets/img2.jpg'),
                    name: '多数据对比柱状图',
                    type: 'charDSJZ',
                },
                {
                    url: require('@/assets/img6.jpg'),
                    name: '折线图',
                    type: 'lineChart',
                },
                {
                    url: require('@/assets/img5.jpg'),
                    name: '折柱混合',
                    type: 'lineHistogram',
                },
                {
                    url: require('@/assets/img3.jpg'),
                    name: '普通饼状图',
                    type: 'charbB',
                },
                {
                    url: require('@/assets/img7.jpg'),
                    name: '联动和共享数据集',
                    type: 'linkageShare',
                },
                {
                    url: require('@/assets/img2.jpg'),
                    name: '对比图',
                    type: 'contrastDSJZ',
                }
            ],
        }
    },
    mounted () {
        this.init()
    },
    created () {
        let heightList = this.$route.params.heightList
        this.$nextTick(() => {
            if (heightList !== "" && heightList !== null && heightList !== undefined) {
                this.canvasHeight = heightList
                this.$refs.canvasBox.style.height = heightList + 'px'
            }
        })
    },
    computed: {},
    methods: {
        changemax (val) {
            let data = []
            for (let i = 0; i < this.maxValue.length; i ++) {
                let datas = {
                    fname: this.maxValue[i].value,
                    name: this.maxValue[i].label
                }
                data.push(datas)
            }
            this.chanrtData.chartChturs[this.chartsIndex].chartSet.forceShowFields = data
        },
        handleInput(e) {
        },
        dataDrawerhandleClose () {
            this.dataDrawer = false
        },
        init () {
            let _that_ = this
            getdataSetlist().then(res => {
                this.dataSourceOption = res
            })
            if (this.$route.params.name) {
                let params = {
                    catId: this.$route.params.id
                }
                this.chanrtData.catName = this.$route.params.name
                this.chanrtData.id = this.$route.params.id
                this.chanrtData.chartChturs = []
                getChartsList(params).then(res => {
                    let data = []
                    for (let i = 0; i < res.length; i ++) {
                        let parameters = {
                            chartSet: {},
                            datasetId: "",
                            filters: [],
                            type: ""
                        }
                        parameters.chartSet = res[i].chartSet
                        parameters.datasetId = res[i].datasetId
                        parameters.type = res[i].chartsType
                        data.push(parameters)
                    }
                    let newres = res
                    getChartsData(data).then(res => {
                        for (let i = 0; i < newres.length; i ++) {
                            newres[i].options = res[i]
                            let option = clone(this.optionInformation)
                            option.id = newres[i].id
                            option.chartsId = newres[i].webConfig.chartsId
                            option.chartsType = newres[i].chartsType
                            option.coordinateX = newres[i].webConfig.coordinateX
                            option.coordinateY = newres[i].webConfig.coordinateY
                            option.width = newres[i].webConfig.width
                            option.height = newres[i].webConfig.height
                            option.title = newres[i].webConfig.title
                            newres[i].chartSet.dataset = newres[i].datasetId
                            option.chartSet = newres[i].chartSet
                            option.chartSet.title = newres[i].webConfig.title
                            option.options = optionsComputed(newres[i].options ,newres[i].chartsType, _that_, newres[i].chartSet.title)
                            this.chanrtData.chartChturs.push(option)
                            this.$nextTick(() => {
                                this.addChars(option.chartsId, option.options, newres[i].chartsType)
                            })
                        }
                        this.optionsid = this.chanrtData.chartChturs.length
                    }).catch(res => {
                        for (let i = 0; i < newres.length; i ++) {
                            newres[i].options = null
                            let option = clone(this.optionInformation)
                            option.id = newres[i].id
                            option.chartsId = newres[i].webConfig.chartsId
                            option.chartsType = newres[i].chartsType
                            option.coordinateX = newres[i].webConfig.coordinateX
                            option.coordinateY = newres[i].webConfig.coordinateY
                            option.width = newres[i].webConfig.width
                            option.height = newres[i].webConfig.height
                            option.title = newres[i].webConfig.title
                            newres[i].chartSet.dataset = newres[i].datasetId
                            option.chartSet = newres[i].chartSet
                            option.chartSet.title = newres[i].webConfig.title
                            option.options = optionsComputed(newres[i].options ,newres[i].chartsType, _that_, newres[i].chartSet.title)
                            this.chanrtData.chartChturs.push(option)
                            this.$nextTick(() => {
                                this.addChars(option.chartsId, option.options, newres[i].chartsType)
                            })
                        }
                        this.optionsid = this.chanrtData.chartChturs.length
                    })
                })
            }
            function optionsComputed(data, type, _that_, title) {
                let option
                if (type == 'histogram') {
                    let data1 = []
                    let data2 = []
                    if (data && data?.length) {
                        for (let i = 0; i < data.length; i ++) {
                            data1.push(data[i].name)
                            data2.push(data[i].value)
                        }
                    } else {
                        data1 = ["衬衫","羊毛衫","雪纺衫","裤子","高跟鞋","袜子"]
                        data2 = [5, 20, 36, 10, 10, 20]
                    }
                    option = {
                        title: {
                            text: title
                        },
                        tooltip: {},
                        legend: {
                            top: '7%'
                        },
                        xAxis: {
                            data: data1,
                            axisLabel: _that_.xAxisAxisLabel,
                        },
                        yAxis: {
                            axisLabel: _that_.yAxisAxisLabel,
                        },
                        series: [{
                            type: 'bar',
                            data: data2,
                        }]
                    }
                } else if (type == 'charDSJZ') {
                    let typedata = []
                    if (data && data?.dimensions?.length) {
                        let newdata = clone(data.dimensions)
                        for (let i = 0; i < newdata.length - 1; i ++) {
                            let dataed = {
                                type: 'bar'
                            }
                            typedata.push(dataed)
                        }
                    } else {
                        typedata = [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
                    }
                    option = {
                        title: {
                            text: title
                        },
                        legend: {
                            top: '7%'
                        },
                        tooltip: {},
                        dataset: {
                            dimensions: data && data.dimensions ? data.dimensions : ['product', '2015', '2016', '2017'],
                            source: data && data.source ? data.source : [
                                { product: 'Matcha Latte', 2015: 43.3, 2016: 85.8, 2017: 93.7 },
                                { product: 'Milk Tea', 2015: 83.1, 2016: 73.4, 2017: 55.1 },
                                { product: 'Cheese Cocoa', 2015: 86.4, 2016: 65.2, 2017: 82.5 },
                                { product: 'Walnut Brownie', 2015: 72.4, 2016: 53.9, 2017: 39.1 }
                            ]
                        },
                        xAxis: {
                            type: 'category',
                            axisLabel: _that_.xAxisAxisLabel,
                        },
                        yAxis: {
                            axisLabel: _that_.yAxisAxisLabel,
                        },
                        // Declare several bar series, each will be mapped
                        // to a column of dataset.source by default.
                        series: typedata
                    }
                } else if(type == 'contrastDSJZ') {
                    let newdata = []
                    let typedata = []
                    if (data && data?.dimensions) {
                        newdata = clone(data.dimensions)
                        for (let i = 0; i < newdata.length - 1; i ++) {
                            let data = {
                                type: 'bar'
                            }
                            typedata.push(data)
                        }
                    } else {
                        typedata = [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
                    }
                    option = {
                        title: {
                            text: title
                        },
                        legend: {
                            top:"7%"
                        },
                        tooltip: {},
                        dataset: {
                            dimensions: data && data.dimensions ? data.dimensions : ['product', '2015', '2016', '2017'],
                            source: data && data.source ? data.source : [
                                { product: 'Matcha Latte', 2015: 43.3, 2016: 85.8, 2017: 93.7 },
                                { product: 'Milk Tea', 2015: 83.1, 2016: 73.4, 2017: 55.1 },
                                { product: 'Cheese Cocoa', 2015: 86.4, 2016: 65.2, 2017: 82.5 },
                                { product: 'Walnut Brownie', 2015: 72.4, 2016: 53.9, 2017: 39.1 }
                            ]
                        },
                        xAxis: {
                            type: 'category',
                            axisLabel: _that_.xAxisAxisLabel,
                        },
                        yAxis: {
                            axisLabel: _that_.yAxisAxisLabel,
                        },
                        // Declare several bar series, each will be mapped
                        // to a column of dataset.source by default.
                        series: typedata
                    }
                } else if (type == 'lineChart') {
                    let data1 = []
                    let data2 = []
                    if (data && data?.length) {
                        for (let i = 0; i < data.length; i ++) {
                            data1.push(data[i].name)
                            data2.push(data[i].value)
                        }
                    } else {
                        data1 = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                        data2 = [150, 230, 224, 218, 135, 147, 260]
                    }
                    option = {
                        title: {
                            text: title
                        },
                        legend: {
                            top:"7%"
                        },
                        xAxis: {
                            type: 'category',
                            data: data1,
                            axisLabel: _that_.xAxisAxisLabel,
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: _that_.yAxisAxisLabel,
                        },
                        series: [
                            {
                            data: data2,
                            type: 'line'
                            }
                        ]
                    }
                } else if (type == 'lineHistogram') {
                    let series = []
                    if (data && data?.cols?.length) {
                        series = data.cols
                    } else {
                        series = [
                            {
                            name: 'Evaporation',
                            type: 'bar',
                            data: [
                                2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
                            ]
                            },
                            {
                            name: 'Precipitation',
                            type: 'bar',
                            data: [
                                2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
                            ]
                            },
                            {
                            name: 'Temperature',
                            type: 'line',
                            yAxisIndex: 1,
                            data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
                            }
                        ]
                    }
                    let rows = []
                    if (data && data?.rows?.length) {
                        rows = data.rows
                    } else {
                        rows = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    }
                    let legend = []
                    let yAxis = []
                    if (data && data?.cols?.length) {
                        legend = data.cols.map(item => item.name)
                        yAxis = data.cols.map(item => {
                            let obj = {
                                type: 'value',
                                name: item.name,
                                axisLabel: _that_.yAxisAxisLabel,
                            }
                            return obj
                        })
                    } else {
                        legend = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                        yAxis = [
                            {
                                type: 'value',
                                name: 'Precipitation',
                                min: 0,
                                max: 250,
                                interval: 50,
                                axisLabel: {
                                    formatter: '{value} ml'
                                }
                            },
                            {
                                type: 'value',
                                name: 'Temperature',
                                min: 0,
                                max: 25,
                                interval: 5,
                                axisLabel: {
                                    formatter: '{value} °C'
                                }
                            }
                        ]
                    }
                    
                    option = {
                        title: {
                            text: title
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                            }
                        },
                        legend: {
                            data: legend,
                            top: '7%'
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: rows,
                                axisPointer: {
                                    type: 'shadow'
                                },
                                axisLabel: _that_.xAxisAxisLabel,
                            }
                        ],
                        yAxis: yAxis,
                        series: series
                    }

                } else if (type == 'charbB') {
                    let dataList = []
                    if (data && data?.length) {
                        for (let i = 0; i < data.length; i ++) {
                            let newdata = {
                                value: data[i].value,
                                name: data[i].name,
                            }
                            dataList.push(newdata)
                        }
                    } else {
                        dataList = [
                            { value: 1048, name: 'Search Engine' },
                            { value: 735, name: 'Direct' },
                            { value: 580, name: 'Email' },
                            { value: 484, name: 'Union Ads' },
                            { value: 300, name: 'Video Ads' }
                        ]
                    }
                    option = {
                        title: {
                            text: title
                        },
                        tooltip: {
                            trigger: 'item'
                        },
                        legend: {
                            type: 'scroll',
                            orient: 'vertical',
                            top: 20,
                            bottom: 20,
                            right:  10,
                        },
                        series: [
                            {
                                name: 'Access From',
                                type: 'pie',
                                radius: '80%',
                                center: ['40%' , '50%'],
                                data: dataList,
                            }
                        ]
                    }
                } else if (type == 'linkageShare') {
                    let newdata = []
                    if (data && data?.source?.length) {
                        for (let i = 0; i < data.source.length-1; i ++) {
                            let datalist = {
                                type: 'line',
                                smooth: true,
                                seriesLayoutBy: 'row',
                                emphasis: { focus: 'series' }
                            }
                            newdata.push(datalist)
                        }
                        let linedata = {
                            type: 'pie',
                            id: 'pie',
                            radius: '50%',
                            center: ['50%', '25%'],
                            emphasis: {
                                focus: 'self'
                            },
                            label: {
                                formatter: '{b}: {@' + data.source[0][1] + '} ({d}%)'
                            },
                            encode: {
                                itemName: data.source[0][0],
                                value: data.source[0][1],
                                tooltip: data.source[0][1]
                            }
                        }
                        newdata.push(linedata)
                    } else {
                        newdata = [
                            {
                                type: 'line',
                                smooth: true,
                                seriesLayoutBy: 'row',
                                emphasis: { focus: 'series' }
                            },
                            {
                                type: 'line',
                                smooth: true,
                                seriesLayoutBy: 'row',
                                emphasis: { focus: 'series' }
                            },
                            {
                                type: 'line',
                                smooth: true,
                                seriesLayoutBy: 'row',
                                emphasis: { focus: 'series' }
                            },
                            {
                                type: 'line',
                                smooth: true,
                                seriesLayoutBy: 'row',
                                emphasis: { focus: 'series' }
                            },
                            {
                                type: 'pie',
                                id: 'pie',
                                radius: '50%',
                                center: ['50%', '25%'],
                                emphasis: {
                                focus: 'self'
                                },
                                label: {
                                formatter: '{b}: {@2012} ({d}%)'
                                },
                                encode: {
                                itemName: 'product',
                                value: '2012',
                                tooltip: '2012'
                                }
                            }
                        ]
                    }
                    option = {
                        title: {
                            text: title
                        },
                        legend: {
                            top:"7%"
                        },
                        tooltip: {
                            trigger: 'axis',
                            showContent: false
                        },
                        dataset: {
                            source: data && data.source ? data.source : [
                                ['product', '2012', '2013', '2014', '2015', '2016', '2017'],
                                ['Milk Tea', 56.5, 82.1, 88.7, 70.1, 53.4, 85.1],
                                ['Matcha Latte', 51.1, 51.4, 55.1, 53.3, 73.8, 68.7],
                                ['Cheese Cocoa', 40.1, 62.2, 69.5, 36.4, 45.2, 32.5],
                                ['Walnut Brownie', 25.2, 37.1, 41.2, 18, 33.9, 49.1]
                            ]
                        },
                        xAxis: {
                            type: 'category',
                            axisLabel: _that_.xAxisAxisLabel,
                        },
                        yAxis: {
                            gridIndex: 0,
                            axisLabel: _that_.yAxisAxisLabel,
                        },
                        grid: { top: '55%' },
                        series: newdata,
                        legend: {
                            type: 'scroll',
                            orient: 'vertical',
                            top: 20,
                            bottom: 20,
                            right: 10,
                        },
                    }
                }
                return option
            }
        },
        // 保存
        handleSave () {
            if (this.chanrtData.catName == '' || this.chanrtData.catName == null || this.chanrtData.catName == undefined) {
                this.$message({
                    message: '请填写标题',
                    type: 'warning'
                })
            } else {
                let optionsList = clone(this.chanrtData)
                let data = optionsList.chartChturs
                for (let i = 0; i < data.length; i++) {
                    let webConfig = {
                        chartsId: '',
                        coordinateX: '',
                        coordinateY: '',
                        width: '',
                        height: '',
                        title: '',
                    }
                    webConfig.coordinateX = data[i].coordinateX
                    webConfig.coordinateY = data[i].coordinateY
                    webConfig.width = data[i].width
                    webConfig.height = data[i].height
                    webConfig.title = data[i].chartSet.title
                    webConfig.chartsId = data[i].chartsId
                    delete data[i].coordinateX
                    delete data[i].coordinateY
                    delete data[i].width
                    delete data[i].height
                    delete data[i].options
                    delete data[i].chartsId
                    delete data[i].chartSet.title
                    if (data[i].chartSet && data[i].chartSet.dataset) {
                        data[i].datasetId = data[i].chartSet.dataset
                    } else {
                        data[i].datasetId = ""
                    }
                    data[i].webConfig = webConfig
                    delete data[i].chartSet.dataset
                }
                optionsList.parentId = this.$route.params.id
                optionsList.datasetId = this.$route.params.dataId || this.$route.params.id
                optionsList.canvasHeight = this.canvasHeight
                // 1111
                setchartsv2(optionsList).then(res => {
                    this.chanrtData.id = res
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    })
                })
            }
        },
        // 关闭
        handleClose (done) {
            this.drawer = false
        },
        dataSelectChange (form) {
            this.chanrtData.chartChturs[this.chartsIndex].chartSet = form
        },
        cancelClick () {
            this.$router.push({
                name: 'chart'
            })
        },
        deletelist () {
            let cloneChartChturs = Object.assign([], this.chanrtData.chartChturs)
            cloneChartChturs.splice(this.chartsIndex, 1)
            this.chanrtData.chartChturs = []
            this.chanrtData.chartChturs = cloneChartChturs
            this.$nextTick(() => {
                for (let i = 0; i < cloneChartChturs.length; i ++) {
                    this.addChars(cloneChartChturs[i].id, cloneChartChturs[i].options)
                }
            })
            // this.chanrtData.chartChturs = cloneChartChturs
            // for (let i = 0; i < cloneChartChturs.length; i ++) {
            //     this.addChars(cloneChartChturs[i].id, cloneChartChturs[i].options)
            // }
            this.formdisabled = false
            this.dataDrawer = false
        },
        // 选择图表
        chartClick (item) {
            if (item.type == 'histogram') {
                let option = Object.assign({}, this.optionInformation)
                option.chartsId = 'histogram' + this.optionsid
                option.chartsType = 'histogram'
                option.chartSet = {
                    dataset: '',
                    row: '',
                    col: '',
                    title: ''
                }
                option.options = {
                    title: {
                        text: '标题'
                    },
                    tooltip: {},
                    xAxis: {
                        data: ["衬衫","羊毛衫","雪纺衫","裤子","高跟鞋","袜子"],
                        axisLabel: {
                            interval: 0,
                            rotate: 30,
                        },
                    },
                    yAxis: {},
                    series: [{
                        type: 'bar',
                        data: [5, 20, 36, 10, 10, 20],
                        // label: {
                        //     show: true,
                        //     position: 'bottom',
                        //     interval: 0,
                        //     rotate: 270,
                        //     align: 'right',
                        //     verticalAlign: 'middle',
                        //     position: 'insideBottom',
                        //     color: 'black',
                        // },
                    }]
                }
                this.chanrtData.chartChturs.push(option)
                this.$nextTick(() => {
                    this.addChars(option.chartsId, option.options)
                })
            } else if (item.type == 'charDSJZ') {
                let option = Object.assign({}, this.optionInformation)
                option.chartsId = 'charDSJZ' + this.optionsid
                option.chartsType = 'charDSJZ'
                option.chartSet = {
                    dataset: '',
                    row: '',
                    col: '',
                    cat: '',
                    title: ''
                }
                option.options = {
                    title: {
                        text: '标题'
                    },
                    legend: {},
                    tooltip: {},
                    dataset: {
                        dimensions: ['product', '2015', '2016', '2017'],
                        source: [
                            { product: 'Matcha Latte', 2015: 43.3, 2016: 85.8, 2017: 93.7 },
                            { product: 'Milk Tea', 2015: 83.1, 2016: 73.4, 2017: 55.1 },
                            { product: 'Cheese Cocoa', 2015: 86.4, 2016: 65.2, 2017: 82.5 },
                            { product: 'Walnut Brownie', 2015: 72.4, 2016: 53.9, 2017: 39.1 }
                        ]
                    },
                    xAxis: {
                        type: 'category',
                        axisLabel: {
                            interval: 0,
                            rotate: 30,
                        },
                    },
                    yAxis: {},
                    // Declare several bar series, each will be mapped
                    // to a column of dataset.source by default.
                    series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
                }
                this.chanrtData.chartChturs.push(option)
                this.$nextTick(() => {
                    this.addChars(option.chartsId, option.options)
                })
            } else if (item.type == 'contrastDSJZ') {
                let option = Object.assign({}, this.optionInformation)
                option.chartsId = 'contrastDSJZ' + this.optionsid
                option.chartsType = 'contrastDSJZ'
                option.chartSet = {
                    dataset: '',
                    row: '',
                    col: '',
                    date: '',
                    cmpType: '0',
                    ut: '0',
                    title: ''
                }
                option.options = {
                    title: {
                        text: '标题'
                    },
                    legend: {},
                    tooltip: {},
                    dataset: {
                        dimensions: ['product', '2015', '2016', '2017'],
                        source: [
                        { product: 'Matcha Latte', 2015: 43.3, 2016: 85.8, 2017: 93.7 },
                        { product: 'Milk Tea', 2015: 83.1, 2016: 73.4, 2017: 55.1 },
                        { product: 'Cheese Cocoa', 2015: 86.4, 2016: 65.2, 2017: 82.5 },
                        { product: 'Walnut Brownie', 2015: 72.4, 2016: 53.9, 2017: 39.1 }
                        ]
                    },
                    xAxis: {
                        type: 'category',
                        axisLabel: {
                            interval: 0,
                            rotate: 30,
                        },
                    },
                    yAxis: {},
                    // Declare several bar series, each will be mapped
                    // to a column of dataset.source by default.
                    series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
                }
                this.chanrtData.chartChturs.push(option)
                this.$nextTick(() => {
                    this.addChars(option.chartsId, option.options)
                })
            } else if (item.type == 'lineChart') {
                let option = Object.assign({}, this.optionInformation)
                option.chartsId = 'lineChart' + this.optionsid
                option.chartsType = 'lineChart'
                option.chartSet = {
                    dataset: '',
                    row: '',
                    col: '',
                    title: ''
                }
                option.options = {
                    title: {
                        text: '标题'
                    },
                    xAxis: {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        axisLabel: {
                            interval: 0,
                            rotate: 30,
                        },
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                        data: [150, 230, 224, 218, 135, 147, 260],
                        type: 'line'
                        }
                    ]
                }
                this.chanrtData.chartChturs.push(option)
                this.$nextTick(() => {
                    this.addChars(option.chartsId, option.options)
                })
            } else if (item.type == 'lineHistogram') {
                let option = Object.assign({}, this.optionInformation)
                option.chartsId = 'lineHistogram' + this.optionsid
                option.chartsType = 'lineHistogram'
                option.chartSet = {
                    dataset: '',
                    row: '',
                    bars: [],
                    lines: [],
                    title: ''
                }
                option.options = {
                    title: {
                        text: '标题'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                        }
                    },
                    // toolbox: {
                    //     feature: {
                    //     dataView: { show: true, readOnly: false },
                    //     magicType: { show: true, type: ['line', 'bar'] },
                    //     restore: { show: true },
                    //     saveAsImage: { show: true }
                    //     }
                    // },
                    legend: {
                        data: ['Evaporation', 'Precipitation', 'Temperature']
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                            axisPointer: {
                                type: 'shadow'
                            },
                            axisLabel: {
                                interval: 0,
                                rotate: 30,
                            },
                        }
                    ],
                    yAxis: [
                        {
                        type: 'value',
                        name: 'Precipitation',
                        min: 0,
                        max: 250,
                        interval: 50,
                        axisLabel: {
                            formatter: '{value} ml'
                        }
                        },
                        {
                        type: 'value',
                        name: 'Temperature',
                        min: 0,
                        max: 25,
                        interval: 5,
                        axisLabel: {
                            formatter: '{value} °C'
                        }
                        }
                    ],
                    series: [
                        {
                        name: 'Evaporation',
                        type: 'bar',
                        data: [
                            2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
                        ]
                        },
                        {
                        name: 'Precipitation',
                        type: 'bar',
                        data: [
                            2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
                        ]
                        },
                        {
                        name: 'Temperature',
                        type: 'line',
                        yAxisIndex: 1,
                        data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
                        }
                    ]
                }
                this.chanrtData.chartChturs.push(option)
                this.$nextTick(() => {
                    this.addChars(option.chartsId, option.options)
                })
            } else if (item.type == 'charbB') {
                let option = Object.assign({}, this.optionInformation)
                option.chartsId = 'charbB' + this.optionsid
                option.chartsType = 'charbB'
                option.chartSet = {}
                option.chartSet = {
                    dataset: '',
                    row: '',
                    col: '',
                    title: ''
                }
                option.options = {
                    title: {
                        text: '标题'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        type: 'scroll',
                        orient: 'vertical',
                        top: 20,
                        bottom: 20,
                        right:  10,
                    },
                    series: [
                        {
                            name: 'Access From',
                            type: 'pie',
                            radius: '80%',
                            center: ['40%' , '50%'],
                            data: [
                                { value: 1048, name: 'Search Engine' },
                                { value: 735, name: 'Direct' },
                                { value: 580, name: 'Email' },
                                { value: 484, name: 'Union Ads' },
                                { value: 300, name: 'Video Ads' }
                            ],
                        }
                    ]
                }
                this.chanrtData.chartChturs.push(option)
                this.$nextTick(() => {
                    this.addChars(option.chartsId, option.options)
                })
            } else if (item.type == 'linkageShare') {
                let option = Object.assign({}, this.optionInformation)
                option.chartsId = 'linkageShare' + this.optionsid
                option.chartsType = 'linkageShare'
                option.chartSet = {
                    dataset: '',
                    row: '',
                    col: '',
                    cat: '',
                    title: ''
                }
                option.options = {
                    title: {
                        text: '标题'
                    },
                    legend: {},
                    tooltip: {
                        trigger: 'axis',
                        showContent: false
                    },
                    dataset: {
                        source: [
                            ['product', '2012', '2013', '2014', '2015', '2016', '2017'],
                            ['Milk Tea', 56.5, 82.1, 88.7, 70.1, 53.4, 85.1],
                            ['Matcha Latte', 51.1, 51.4, 55.1, 53.3, 73.8, 68.7],
                            ['Cheese Cocoa', 40.1, 62.2, 69.5, 36.4, 45.2, 32.5],
                            ['Walnut Brownie', 25.2, 37.1, 41.2, 18, 33.9, 49.1]
                        ]
                    },
                    xAxis: {
                        type: 'category',
                        axisLabel: {
                            interval: 0,
                            rotate: 30,
                        },
                    },
                    yAxis: { gridIndex: 0 },
                    grid: { top: '50%' },
                    legend: {
                        type: 'scroll',
                        orient: 'vertical',
                        right: 10,
                        top: 20,
                    },
                    series: [
                        {
                            type: 'line',
                            smooth: true,
                            seriesLayoutBy: 'row',
                            emphasis: { focus: 'series' }
                        },
                        {
                            type: 'line',
                            smooth: true,
                            seriesLayoutBy: 'row',
                            emphasis: { focus: 'series' }
                        },
                        {
                            type: 'line',
                            smooth: true,
                            seriesLayoutBy: 'row',
                            emphasis: { focus: 'series' }
                        },
                        {
                            type: 'line',
                            smooth: true,
                            seriesLayoutBy: 'row',
                            emphasis: { focus: 'series' }
                        },
                        {
                            type: 'pie',
                            id: 'pie',
                            radius: '50%',
                            center: ['50%', '25%'],
                            emphasis: {
                            focus: 'self'
                            },
                            label: {
                            formatter: '{b}: {@2012} ({d}%)'
                            },
                            encode: {
                            itemName: 'product',
                            value: '2012',
                            tooltip: '2012'
                            }
                        }
                    ]
                }
                this.chanrtData.chartChturs.push(option)
                this.$nextTick(() => {
                    this.addChars(option.chartsId, option.options)
                })
            }
            this.optionsid += 1
            this.drawer = false
        },
        selectChart () {
            this.drawer = true
        },
        addChars (boxId, boxOption, type) {
            var chartDom = document.getElementById(boxId);
            var myChart = echarts.init(chartDom);
            var option = boxOption
            if (type == "linkageShare") {
                setTimeout(() => {
                    myChart.on('updateAxisPointer', function (event) {
                        const xAxisInfo = event.axesInfo[0];
                        if (xAxisInfo) {
                        const dimension = xAxisInfo.value + 1;
                        myChart.setOption({
                            series: {
                            id: 'pie',
                            label: {
                                formatter: '{b}: {@[' + dimension + ']} ({d}%)'
                            },
                            encode: {
                                value: dimension,
                                tooltip: dimension
                            }
                            }
                        });
                        }
                    });
                    myChart.setOption(option);   
                });
                let listener = function () {
                    myChart.resize()
                };
                EleResize.on(chartDom, listener)
            } else {
                myChart.hideLoading()
                myChart.setOption(option);
                let listener = function () {
                    myChart.resize()
                };
                EleResize.on(chartDom, listener)
            }
        },
        dbclickcharts (item, idnex) {
            let ids = {
                id: item.chartSet.dataset
            }
            getdataBaseLinkDetailt(ids).then(res => {
                let data = []
                for (let i = 0; i < res.fieldPropConfigList.length; i ++) {
                    let datas = {
                        label: res.fieldPropConfigList[i].showName,
                        value: res.fieldPropConfigList[i].fname,
                    }
                    data.push(datas)
                }
                this.maxValueOptions = data
            })
            if(!item.chartSet.dataset) {
                item.chartSet.dataset = this.$route.params.dataId || this.$route.params.id
            }
            this.dataSourceCondition = item.chartSet
            this.positionData.height = item.height
            this.positionData.width = item.width
            this.positionData.coordinateX = item.coordinateX
            this.positionData.coordinateY = item.coordinateY
            if (item?.chartSet?.forceShowFields?.length) {
                let maxData = []
                for (let i = 0; i < item.chartSet.forceShowFields.length; i ++) {
                    let datas = {
                        value: item.chartSet.forceShowFields[i].fname,
                        label: item.chartSet.forceShowFields[i].name,
                    }
                    maxData.push(datas)
                }
                this.maxValue = maxData
            } else {
                this.maxValue = []
            }
            this.chartsIndex = idnex
            this.formdisabled = true
            this.dataDrawer = true
            if (item.chartsType == 'histogram') {
                this.chartsType = 'histogram'
            } else if (item.chartsType == 'charDSJZ') {
                this.chartsType = 'charDSJZ'
            } else if(item.chartsType == 'contrastDSJZ') {
                this.chartsType = 'contrastDSJZ'
            }
             else if (item.chartsType == 'lineChart') {
                this.chartsType = 'lineChart'
            } else if (item.chartsType == 'lineHistogram') {
                this.chartsType = 'lineHistogram'
            } else if (item.chartsType == 'charbB') {
                this.chartsType = 'charbB'
            } else if (item.chartsType == 'linkageShare') {
                this.chartsType = 'linkageShare'
            }
        },
        // 画布高度失去焦点
        handleBlur(e) {
            this.$refs.canvasBox.style.height = this.canvasHeight + 'px'
        },
        handlewidthBlur (e) {
            this.$refs.canvasBox.style.width = this.canvasWidth + 'px'
        }
    }
}
</script>
<style scoped lang="less">
.setchanrt {
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    .topBox {
        height: 7%;
        width: 100%;
        border: 1px solid rgb(164, 159, 159);
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 0 10px;
    }
}
.chartsBox {
    // width: 100%;
    // height: 95%;
    // border: 1px solid rgb(164, 159, 159);
    box-sizing: border-box;
    .leftbox {
        width: 100%;
        height: 100%;
        float: left;
        // overflow: hidden;
        // overflow-y: scroll;
        background: #ffffff;
    }
    // .rightbox {
    //     width: 15%;
    //     height: 100%;
    //     background: rgb(241, 240, 240);
    //     float: left;
    //     border-left: 1px solid rgb(164, 159, 159);
    //     box-sizing: border-box;
    //     h4 {
    //         width: 100%;
    //         text-align: center;
    //         padding: 10px 0;
    //         border-bottom: 1px solid rgb(164, 159, 159);
    //         margin-bottom: 10px;
    //     }
    // }
}
// /deep/ .el-drawer__wrapper {
//     position: absolute;
// }
.imgbox {
    width: 200px;
    height: 230px;
    padding: 5px;
    margin: 5px;
    box-sizing: border-box;
    border: 1px solid black;
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        width: 90%;
        height: auto;
    }
    span {
        margin: 10px 0;
    }
}
.chartsBoxSun {
    border: 1px solid black;
    margin: 5px;
    float: left;
}
.drag-resize-area {
    position: relative;
    .drag-item-echarts{
        border: 1px solid;
        border-color: rgb(84, 112, 198);
        // height: 300px;
        // width: 400px;
        .drag-item-echarts-histogram{
            height: 100%;
            width: 100%;
            margin: auto auto;
            padding: 0;
        }
    }
}    
</style>
